import React from 'react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'block',
    position: 'fixed',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      left: '37%',
      top: '40%',
      width: 90,
      height: 80,
    },
    left: '45%',
    top: '40%',
    width: 110,
    height: 100,
    zIndex: 999,
  }
}));

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  color: #fcf9f9;
  background-color: rgba(0, 0, 0); /* Black w/ opacity */
  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

export default function Loader() {
  const classes = useStyles();

  return (
    <div className="sweet-loading">
      <DarkBackground disappear={true}>
        <img src={require('../../images/loader.gif').default} className={classes.loader} alt=""/>
      </DarkBackground>
    </div>
  );
}
