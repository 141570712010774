import * as React from 'react';
import {useEffect, useState} from 'react';
import {LandingComponent} from '../component/Landing/LandingComponent/LandingComponent';
import {makeStyles} from '@material-ui/core';
import Layout from '../component/Landing/Layout/Layout';
import Loader from '../shared/Loader/Loader';

const useStyles = makeStyles(theme => ({
  main: {
    color: '#232129',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Raleway:wght@300',
  },
}));

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setInterval(() => {
      setIsOpen(true);
    }, 2000);
  }, []);

  return (
    <Layout>
      {isOpen ? <LandingComponent/> : <Loader/>}
    </Layout>
  );
};

export default IndexPage;
